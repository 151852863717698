import { css } from "@emotion/react"
import styled from "@emotion/styled"
import React from "react"
import logo from "../images/layout/nav/logo.png"
import { BlueBtn } from "./layout/styledComponents"

const StyledButton=styled(BlueBtn)`
bottom:2.5em;

position:relative;

`;

const BrandBox = styled.div`
  display: none;
  text-align: center;
  z-index: 1;
  margin-top: 30px;
 
  @media (min-width: 1280px) {
    display: block;
  }

  @media (min-width: 1280px) {
    margin-top: 0;
  
  }
`

const H2 = styled.h2`
  color: ${props => props.theme.grey};
  margin: 0;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: 122%;
`
export default function Brand() {
  return (
    <BrandBox>
      <img src={logo} alt="logo" width={413} height={342} />
      <h1
        css={css`
          margin: 16px 0;
        `}
      >
        pikes peak
      </h1>
      <H2>ORAL SURGERY</H2>
      <p
        css={theme => css`
          letter-spacing: 0.1em;
          line-height: 122%;
          margin-bottom: 88px;
          color: ${theme.grey};
        `}
      >
        & DENTAL IMPLANT CENTER
      </p>

      <StyledButton as="a" href="#contact">
        Contact us
      </StyledButton>
    </BrandBox>
  )
}
