import React from "react"
import HomeHero from "../components/HomeHero"
import Layout from "../components/layout/Layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout learnMore homepage displayReviews>
    <Seo />
    <HomeHero />
  </Layout>
)

export default IndexPage
