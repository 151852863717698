import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Section = styled.section`
  background: ${props => props.theme.bg};
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  padding: 16px;
  
  @media (min-width: 660px) {
    padding: 16px 40px;
    padding-top:3vh;
    padding-bottom:5vh;
  }

  @media (min-width: 1440px) {
    background: rgba(248, 248, 248, 0.5);
    padding: 16px max(16px, calc((100vw - 1750px) / 2));
  }
`

const Box = styled.div`
padding-top:1em;
  text-align: center;
  @media (min-width: 1440px) {
    width: calc(100% / 3);
  }
`

const Heading = styled.h2`
  display: none;
  margin: 16px 0 0;
  color: ${props => props.theme.blue};
  font-size:1.5em;
  @media (min-width: 1030px) {
    display: block;
  }
`

const Year = styled.h3`
  margin: 10px auto 0;
  &,
  + p {
    font-size: 0.875rem;
    line-height: 122%;
    font-weight: 500;
    max-width: 87px;
    color: ${props => props.theme.black};
  }

  + p {
    margin: 0 auto;
  }

  @media (min-width: 768px) {
    &,
    + p {
      font-size: 1rem;
      line-height: 122%;
      font-weight: 700;
      max-width: 107px;
    }
  }

  @media (min-width: 1024px) {
    &,
    + p {
      margin: 16px auto 0;
      font-size: 1.5rem;
      line-height: 122%;
      font-weight: 700;
      max-width: 150px;
    }

    + p {
      margin: 0 auto;
    }
  }

  @media (min-width: 1440px) {
    display: block;

    + p {
      display: none;
    }
  }
`
export default function Awards() {
  return (
    <Section>
      <Box>
        <a href='https://www.coloradospringsmag.com/top-dentists-2020/article_f2ca508e-9474-57a5-aba2-60a1cf7da799.html '>
        <StaticImage
          src="../images/awards/1.png"
          alt="award"
          width={200}
          placeholder="blurred"
          css={css`
            @media(max-width:768px){
            max-width:60px;
          }
          @media(min-width:768px){
            max-width:100px;
          }
          `}
        />
        <Heading>top dentists</Heading>
        <Year>2014-{new Date().getFullYear()}</Year>
        </a>
      </Box>
      <Box>
      <a href='https://www.coloradospringsmag.com/2021-colorado-springs-top-dentists-list/article_47a47e60-ad1d-11eb-bd34-6feaed789fea.html#oralandmaxillofacial'>
        <StaticImage
          src="../images/awards/2.png"
          alt="award"
          placeholder="blurred"
          width={200}
          css={css`
               @media(max-width:768px){
            max-width:60px;
          }
          @media(min-width:768px){
            max-width:100px;
            position:relative;
    
          }
          `}
        />
        <Heading>outstanding volunteer</Heading>
        <Year second>2014-{new Date().getFullYear()}</Year>
      </a>
      </Box>
      <Box>
      <a href='https://dentallifeline.org/volunteer-recognition/dln-%e2%80%a2-colorado-recognizes-outstanding-volunteers/'>
        <StaticImage
          src="../images/awards/3.png"
          alt="award"
          placeholder="blurred"
          width={200}
          css={css`
          @media(max-width:768px){
       width:100px;
     }
     @media(min-width:768px){
      width:200px;
     
     }
     `}
        />
        <Heading>Dentist of the year</Heading>
        <Year>2016-{new Date().getFullYear()}</Year>
</a>
      </Box>
    </Section>
  )
}
