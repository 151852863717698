import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, StaticImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import React from "react"
import bgLogo from "../images/home-hero/logo.svg"
import mirror from "../images/home-hero/mirror.png"
import triangle from "../images/home-hero/triangle.png"
import Awards from "./Awards"
import Brand from "./Brand"
import { BlueBtn } from "./layout/styledComponents"
import { theme } from './layout/GlobalStyles';


const BackG=styled.div`
height: 100vh;
background:url(${bgLogo});
background-repeat: no-repeat;
background-size:150vh;
background-position:50vw -25vh;
@media(max-width:768px){
  background-position:25vw -25vh;
}

`
const Hero = styled.div`

.number{
  position:absolute;
  bottom: 120px;
  color:#00C2FF;
  @media(min-width:768px){
    display:none;
  }
}
 
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  position: relative;
  max-width: 1400px;
  margin: 0 auto;

  @media (orientation: landscape) {
    max-height: 150vh;
  }

  ::before {
    content: "";
    position: absolute;
    left: -110px;
    bottom: 0;
    width: 1456px;
    height: 1000px;
    background: url(${triangle}) no-repeat;
    background-size: contain;
    z-index: -1;

    @media(max-width:768px){
      left: -150px;
    }
  }

  @media (min-width: 450px) {
    ::before {
      left: -50px;
    }
  }

  @media (min-width: 660px) {
    ::before {
      left: 0;
    }
  }
  @media (min-width: 768px) {
    height: 95vh;
    margin-top:20px;
  }

  @media (min-width: 1024px) {
    height: 100vh;
    margin-top:20px;
  }

  @media (min-width: 1280px) {
    justify-content: space-between;
    padding-top: 100px;
    height: 999px;
    height: 100vh;
    ::before {
      right: 0;
      left: unset;
    }
  }


  @media (min-width: 1640px) {
    ::before {
      display: none;
    }
  }
`

const DoctorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 30px;
  position: relative;

  @media (orientation: landscape) {
    margin-top: 0;
  }

  ::after {
    content: "";
    width: 230px;
    display:none;
    height: 87px;
    background: url(${mirror}) no-repeat;
    background-size: cover;
    position: absolute;
    bottom: -18px;
    right: -8px;
  }

  @media (min-width: 550px) {
    ::after {
      width: 287.5px;
      height: 109px;
      bottom: -25px;
    }
  }

  @media (min-width: 768px) {

    ::after {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    margin-top: 0;

  }

  @media (min-width: 1280px) {
    
    margin-right: 100px;
    margin-left: 100px;
  }
`

const DoctorDetails = styled.div`
  padding: 8px;
  position:absolute;
  background: ${props => props.theme.blue};
  box-shadow: -8px -8px 32px #FFFFFF, 8px 8px 32px rgba(0, 194, 255, 0.3);
  border-radius: 8px;
  max-width: 184px;
  color: ${props => props.theme.white};
  left: 0;
  z-index: 10;
  top: calc(45vh + 1vw);

  :last-of-type {
    left: unset;
    right: 0;
    top: unset;
    bottom: 50vh;
  }

  > h3 {
    font-size: 1.2rem;
    line-height: 122%;
    color: ${props => props.theme.white};
    margin: 0;

    @media (min-width: 1024px) {
      font-size: 1.5rem;
    }
  }

  > p {
  font-size: 0.75rem;
  line-height: 122%;
    margin: 16px 0 0;
    display: none;
  }
 
  @media (min-width: 768px) {
    margin-top:00px;
    position: absolute;
    top: 66%;
    left: -100px;
    transform: none;
    margin-left: 0;
    padding: 16px;
    max-width: 243px;

   
  :last-of-type {
    left: unset;
    right: -100px;
    top: 66%;
    bottom: unset;
  }

  > p {
    display: block;

  }
  }

  @media (min-width: 1024px) {

    > p {
    font-size: 0.875rem;

    }
  }

  @media (min-width: 1280px) {
    max-width: 280px;
  }
`

const Contact = styled(BlueBtn)`
  position: absolute;
  bottom: 36px;
  left: 50%;
  transform: translateX(-50%);
  @media (min-width: 768px) {
    bottom: 0;
  }
  @media (min-width: 1030px) {
    bottom: 36px;
  }
  @media (min-width: 1280px) {
    display: none;
  }
`

const DesktopBg = styled.div`
  @media (min-width: 1640px) {
    ::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 432px;
      background: url(${triangle}) no-repeat;
      background-size: cover;
      z-index: -1;
    }

    ::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      bottom: 432px;
      width: 1250px;
      background-size: contain;
      background-position: right center;
      z-index: -12;
    }
  }
`

const imgStyles = css`
opacity: 0.9999; //safari overflow bug fix
border-radius:100%;
background: ${theme.white};
  
img {
  transform: translate(1%,15%) !important;
}

@media(max-width:768px){
  margin-left:3em;
  margin-right:3em;
  margin-top:50vh;
  margin-bottom:55vh;
}


@media (min-width: 660px) {
  width: 434px;
  height: 434px;
}

@media (min-width: 768px) {
  width: 542px;
  height: 542px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 8px 8px 32px rgba(49, 108, 143, 0.3);
  backdrop-filter: blur(50px);
}


@media (min-width: 1280px) and (min-height: 800px) {
  width: 675px;
  height: 675px;
}
  
`

export default function HomeHero() {
  const { mobileBg, desktopBg } = useStaticQuery(
    graphql`
      query {
        mobileBg: file(relativePath: { eq: "home-hero/logo-unblurred.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 90, layout: CONSTRAINED, placeholder: NONE)
          }
        }
        desktopBg: file(relativePath: { eq: "home-hero/logo-unblurred.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 90, layout: CONSTRAINED, placeholder: NONE)
          }
        }
      }
    `
  )

  let image = getImage(mobileBg)

  if (typeof window !== "undefined" && window.innerWidth >= 1640) {
    image = getImage(desktopBg)
  }

  return (
    <>
    <BackG>
      <DesktopBg>
        <Hero>
          <Brand />
          <DoctorContainer>
            <DoctorDetails>
              <h3>
              Dr. Trent W. Listello
              </h3>
              <p>
              Board Certified Oral & Maxillofacial Surgeon
              </p>
            </DoctorDetails>

            <StaticImage
              src="../images/home-hero/doc1.png"
              alt="dr michael s. kunkel, dds"
              loading="eager"
              placeholder="blurred"
              width={675}
              height={675}
              css={imgStyles}
            />
            
            <DoctorDetails>
              <h3>
                DR. MICHAEL S.
                <br /> KUNKEL
              </h3>
              <p>
                Board Certified Oral & Maxillofacial Surgeon
              </p>
            </DoctorDetails>
          </DoctorContainer>
          <a
        href="tel:17192646070" className='number'>(719) 264 6070</a>
          <Contact as="a" href="#contact">  
            Contact us
          </Contact>
        </Hero>
      </DesktopBg>
      </BackG>
      <Awards />
  
    </>
  )
}
